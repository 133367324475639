section {
    &#hero {
        padding-top: 120px;
        @include media-breakpoint-up(md) {
            padding-top: 160px;
        }
        @include media-breakpoint-up(lg) {
            padding: 160px 0;
        }
        @include media-breakpoint-up(xxl) {
            padding: 250px 0;
        }
        .background-image-container img {
            object-position: left top;
            @include media-breakpoint-up(md) {
                object-position: center top;
            }
        }
    }
    &#section-2, 
    &#section-6, 
    &#section-6-2,
    &#steps-block {
        .bg-white {
            padding: 3rem 1rem;
            @include media-breakpoint-up(lg) {
                padding: 3rem;
            }
            @include media-breakpoint-up(xl) {
                padding: 5rem;
            }
        }
    }
    &#section-2 {
        .bg-white img {
            max-width: 56.5px;
        }
    }
    &#section-3 {
        .slider {
            .slide {
                &.slide-1 img { max-width: 130px; }
                &.slide-2 img { max-width: 127px; }
                &.slide-3 img { max-width: 81px; }
                &.slide-4 img { max-width: 156px; }
                &.slide-5 img { max-width: 106px; }
                &.slide-6 img { max-width: 115px; }
                &.slide-7 img { max-width: 102px; }
            }
        }
    }
    &#section-6,
    &#section-11 {
        .background-image-container img {
            object-fit: contain;
            object-position: center bottom;
            @include media-breakpoint-up(xxl) {
                object-fit: cover;
            }
        }
    }
    &#section-6, 
    &#section-6-2 {
        padding-bottom: 24%;
        .background-image-container img {
            background-color: $color-gray;
        }
    }
    
    &#section-8 {
        .bg-white {
            padding: 5.5rem 3rem;
            @include media-breakpoint-up(lg) {
                padding: 6rem 3rem;
            }
            @include media-breakpoint-up(xl) {
                padding: 9.5rem 7rem;
            }
        }
        .background-image-container img {
            @include media-breakpoint-down(sm) {
                object-position: left center;
            }
        }
    }
    &#section-9 {
        img {
            @include media-breakpoint-up(md) {
                max-width: 352px;
            }
        }
    }
    &#section-10 {
        .background-image-container img {
            object-position: -50px center;
            @include media-breakpoint-up(lg) {
                object-position: center center;
            }
        }
    }
    &#section-11 {
        padding-bottom: 35%;
        .btn-section {
            p {
                @include media-breakpoint-up(lg) {
                    width: 69%;
                }
            }
        }
    }
    &#section-12 {
        p,
        a {
            font-size: 1.4rem;
            line-height: 1.4;
        }
    }
}