// --------------------
// Include Fonts Here
// --------------------
@font-face {
    font-family: 'PlayfairDisplay-Regular';
    src: url('../../fonts/PlayfairDisplay-Regular.woff2') format('woff2'),
    url('../../fonts/PlayfairDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway-Regular';
    src: url('../../fonts/Raleway-Regular.woff2') format('woff2'),
    url('../../fonts/Raleway-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PlayfairDisplay-Bold';
    src: url('../../fonts/PlayfairDisplay-Bold.woff2') format('woff2'),
    url('../../fonts/PlayfairDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


$headings-font: 'PlayfairDisplay-Regular', sans-serif;
$body-font: 'Raleway-Regular', sans-serif;

$playfair-bold-font: 'PlayfairDisplay-Bold', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    @include font-smoothing('on');
    font-size: 1.85rem;
    line-height: 1.6;
    font-family: $body-font;
}

p {
    margin-bottom: 3rem;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.2;
    margin-bottom: 3rem;
    font-family: $headings-font;
    color: $secondary;
    font-weight: normal;
    text-transform: capitalize;
}

h1, .h1 {
    font-size: 3.5rem;
    @include media-breakpoint-up(sm) {
        font-size: 4rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 4.75rem;
    }
}

h2, .h2 {
    font-size: 3.3rem;
    @include media-breakpoint-up(sm) {
        font-size: 3.8rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: 4rem;
    }
}

h3, .h3 {
    font-size: 3.85rem;
    @include media-breakpoint-up(lg) {
        font-size: 5.85rem;
    }
}

h4, .h4 {
    font-size: 2.2rem;
    @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
    }
}

h5, .h5 {
    font-size: 2.1rem;
}

h6, .h6 {
    font-size: 1.8rem;
}

strong {
	font-family: $headings-font;
	letter-spacing: 0.7px;
}
a {
	outline: none !important;
	text-decoration: none;
}
.border-top-center,
.border-bottom-center,
.border-bottom-left {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: calc(50% - 54px);
		width: 108px;
		height: 1px;
		background-color: #c7c2c8;

	}
}
.border-top-center:before {
  	top: 0;
}
.border-bottom-left:before {
	@include media-breakpoint-up(lg) {
		left: 0;
	}
}