#steps-block {
    .step {
        position: relative;
        &-number {
            background: $color-light-blue;
            color: #fff;
            width: 50px;
            height: 50px;
            line-height: 1.3;
            border-radius: 50%;
            @include media-breakpoint-up(md) {
                width: 70px;
                height: 70px;
                line-height: 1.7;
            }
            @include media-breakpoint-up(lg) {
                width: 105px;
                height: 105px;
                line-height: 1.5;
            }
        }
        &.step-1 { z-index: 5; }
        &.step-2 { z-index: 4; }
        &.step-3 { z-index: 3; }
        &.step-4 { z-index: 2; }
        &.step-5 { z-index: 1; }

    }
}