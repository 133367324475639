
// --------------------
// GLOBAL COMPONENTS
//---------------------

// Generic Section Padding - adds padding to each section - most of your sections should use this - override for special cases
section {
    padding: 60px 0px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 80px 0px;
    }
    @include media-breakpoint-up(lg) {
        padding: 120px 0px;
    }
    &.less-pad {
        padding: 40px 0
    }

    &.large-pad {
      @include media-breakpoint-up(lg) {
        padding: 120px 0
      }
      @include media-breakpoint-up(xl) {
        padding: 160px 0
      }
    }

}

// Background Images - positions the background image behind the content in the section
.background-image-container {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

	img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
    
    // Background Overlay - Adds a colour overlay with transparency options
    &.semi-transparent-bg {
        &-dark {
            background: #000;
            img {
                opacity: 0.6;
            }
        }
        &-light {
            background: #fff;
            img {
                opacity: 0.4;
            }
        }
    }
}

// Youtube Video Embeds
.video-preview-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    
    iframe,
    object,
    embed,
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

	img{
		width: 100%;
	}

    .overlay{
        z-index: 4;
    }

	.play-button {
        width: 100px;
		position: absolute;
		left: calc(50% - 50px);
		top: calc(50% - 40px);
		padding: 0;
		margin: 0;
		border: none;
		appearance: none;
		background: none;
		z-index: 5;
		transition: .8s ease;

		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
		&:focus {
			outline: 0;
		}
	}
}

.max-width-image {
  width: 100%;
  max-width: 300px;
  @include media-breakpoint-up(md) {
    max-width: 400px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
}

.container-fluid {
    max-width: 1750px;
}

.color {
    &-primary {
        color: $primary;
    }
    &-secondary {
        color: $secondary;
    }
}
.bg {
  &-gray {
    background-color: $color-gray;
  }
  &-dark-violet {
    background-color: $color-dark-violet;
  }
}

.bg-white {
  box-shadow: 0px 9px 9px 0px rgba(144, 130, 145, 0.16);
  @include media-breakpoint-up(md) {
    box-shadow: 0px 35.5px 38px 0px rgba(144, 130, 145, 0.16);
  }
}
img {
  width: 100%;
}


@include media-breakpoint-up(xl) {
  .min-width-xl {
    min-width: 650px
  }
}