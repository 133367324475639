// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin-bottom: 12px;
    font-size: 2rem;
    line-height: 1;
    text-transform: capitalize;
    letter-spacing: 0.4px;
    padding: 20px 5px;
    white-space: normal;
    font-family: $playfair-bold-font;
    color: #fff;
    transition: background-color .4s ease-in-out;
    outline: none !important;
    @include media-breakpoint-up(md) {
        padding: 34.5px 10px;
    }
}

.btn-section {
    p {
        width: 80%;
        margin: 0 auto;
        font-size: 1.4rem;
    }
}