// --------------------
// VARIABLES
//---------------------

$headerheight   : 120px;  // Change to approximate header height - adds padding to first section
$header-starting-width: 163px;
$header-scrolled-width: 120px;

// Colors
$body-color		: #303030;
$primary        : #415aa2;
$secondary      : #000000;

$headerbg       : #fff; // Header scrolled background color.

$color-gray: #f0f0f0;
$color-light-blue: #89a8fa;
$color-dark-violet: #42253c;

// --------------------
// Container Options
//---------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  );
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
  xl: 1140px,
  xxl: 1600px,
  xxxl: 1800px,
);
