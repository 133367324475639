body.thank-you {
    #hero {
        min-height: 95vh;
        .background-image-container img {
            object-position: left top;
            @include media-breakpoint-up(md) {
                object-position: calc(100% + 360px) top;
            }
            @include media-breakpoint-up(xl) {
                object-position: center top;
            }
        }
    }
}