
ul, ol {
	&.unstyled-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	&.check-list {
		img {
			min-width: 25px;
			width: 100%;
			@include media-breakpoint-up(md) {
				min-width: 38.5px;
			}
		}
	}
	&.footer-list {
		line-height: 1;
		word-break: break-word;
	}

}

ol {
	&.unstyled-list {
		counter-reset: ol-counter;
		li {
			counter-increment: ol-counter;
			&::before {
				content: counter(ol-counter)'.';
				color: $primary;
				font-size: 3rem;
				margin-right: 1.8rem;
				line-height: 1;
				@include media-breakpoint-up(md) {
					font-size: 5rem;
				}
			}
		}
	}
}
