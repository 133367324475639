form {
    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=date],
    textarea,
    select {
        width: 100%;
        padding: 20px 15px 20px 50px;
        box-shadow: 0px 35.5px 38px 0px rgba(144, 130, 145, 0.16);
        border: none;
        margin-bottom: 15px;
        line-height: 1;
        outline: none !important;
        @include media-breakpoint-up(sm) {
            padding: 27px 15px 27px 70px;
        }
        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
        &::placeholder {
            color: rgba($body-color, 0.7);
        }
    }

    textarea {
        height: 215px !important;
    }

    select {
        appearance: none;
        opacity: 1;
        color: rgba($body-color, 0.7) !important;
    }

    label {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;
        &.error {
            display: block;
            margin-bottom: 20px;
            margin-top: -10px;
            color: red;
            font-size: 1.9rem;
        }
        .icon {
            position: absolute;
            top: 20px;
            left: 15px;
            background-size: 80%;
            width: 16px;
            z-index: 999;
            margin: auto;
            background-repeat: no-repeat;
            background-position: center center;
            @include media-breakpoint-up(sm) {
                left: 25px;
                top: 25px;
                width: 21px;
            }
            &.icon-arrow {
                right: 20px;
                left: inherit;
                width: 16px;
            }
        }
        &.field-1 {
            z-index: 6;
        }
        &.field-2 {
            z-index: 5;
        }
        &.field-3 {
            z-index: 4;
        }
        &.field-4 {
            z-index: 3;
        }
        &.field-5 {
            z-index: 2;
        }
        &.field-6 {
            z-index: 1;
        }
    }

    .field-error{
        color:red;
        font-size: 1.6rem;
        margin: -5px 0 15px;
    }

    .form-messages {
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }
}