header {
    padding: 15px;
    background-color: transparent;
    transition: background-color 0.5s ease-in-out;
    &.scrolled {
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
        background-color: $color-gray;
    }
}

.header-logo img {
    transition: width 0.2s ease-in-out;
    width: $header-scrolled-width;
    @include media-breakpoint-up(md) {
        width: $header-starting-width;
    }
    .scrolled & {
        @include media-breakpoint-up(md) {
            width: $header-scrolled-width;
        }
    }
}

.header-phone {
    line-height: 1.2;
    text-decoration: none !important;
    color: $secondary;
    .phone-icon {
        img,
        svg {
            width: 48.5px;
        }
    }
    span {
        color: $color-dark-violet;
    }

    em {
        font-size: 2.9rem;
        font-style: normal;
        font-family: $playfair-bold-font;
    }
}